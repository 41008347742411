import { render, staticRenderFns } from "./Addons.vue?vue&type=template&id=db2f3828&scoped=true"
import script from "./Addons.vue?vue&type=script&lang=js"
export * from "./Addons.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template_bkwsdkqzqjxfpowqrllowlhnoe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db2f3828",
  null
  
)

export default component.exports