import { render, staticRenderFns } from "./Abstract.vue?vue&type=template&id=1c0a4e46&scoped=true"
import script from "./Abstract.vue?vue&type=script&lang=js"
export * from "./Abstract.vue?vue&type=script&lang=js"
import style0 from "./Abstract.vue?vue&type=style&index=0&id=1c0a4e46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_vue-template_bkwsdkqzqjxfpowqrllowlhnoe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c0a4e46",
  null
  
)

export default component.exports